<template>
    <div>
        <div class="order_query" v-show="orderShow">
            <img :src="require('../assets/images/order_big_tg.png')" >
            <span>订单查询</span>
        </div>
        <van-form @submit="queryOrder" v-show="orderShow">
            <van-cell-group inset>
                <van-field
                        v-model="userName"
                        name="用户名"
                        label="姓名"
                        placeholder="请填写购买人姓名"
                        :rules="[{ required: true, message: '请填写姓名' }]"
                />
                <van-field
                        v-model="phone"
                        type="tel"
                        name="联系电话"
                        label="联系电话"
                        placeholder="请填写购买人联系电话"
                        :rules="[{ required: true, message: '请填写联系电话' }]"
                />
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">
                    查询订单
                </van-button>
            </div>
        </van-form>

        <van-list style="margin-bottom: 30%">
            <van-cell v-for="item in orderList" :key="item">
                <table style="width: 6.0rem;margin: 0 auto;border-bottom: 1px #1989FA solid">
                    <tr>
                        <th style="width: 2rem;">姓名：</th>
                        <td>{{item.userName}}</td>
                    </tr>
                    <tr>
                        <th>联系电话：</th>
                        <td>{{item.phone}}</td>
                    </tr>
                    <tr>
                        <th>收货地址：</th>
                        <td >{{item.postProvince}} {{item.postCity}} {{item.postDistrict}} ***</td>
                    </tr>
                    <tr v-if="item.phoneNumber != null">
                        <th>下单号码：</th>
                        <td >{{item.phoneNumber}}</td>
                    </tr>
                    <tr>
                        <th>订单状态：</th>
                        <td v-if="item.orderType == 1">

                            <span v-if="item.orderStatus == 3 && item.expressNo != null">已发货</span>
							<span v-else-if="item.orderStatus == 3">下单成功</span>
                            <span v-else-if="item.orderStatus == 5">已激活</span>
                            <span v-else >待处理</span>
                        </td>
                        <td v-if="item.orderType == 2">
                            <span v-if="item.orderStatus == 4 && item.expressNo != null">已发货</span>
                            <span v-else-if="item.orderStatus == 5" >已激活</span>
                            <span v-else >待处理</span>
                        </td>
                        <td v-if="item.orderType == 3">
                            <span v-if="item.orderStatus == 1 && item.expressNo != null">已发货</span>
                            <span v-else-if="item.orderStatus == 2">已激活</span>
                            <span v-else >待处理</span>
                        </td>
                    </tr>
                    <tr v-if="item.expressComponey != null">
                        <th>物流方式：</th>
                        <td>{{item.expressComponey}}</td>
                    </tr>
                    <tr v-if="item.expressNo != null">
                        <th>物流单号：</th>
                        <td>{{item.expressNo}}</td>
                    </tr>
                </table>
            </van-cell>
        </van-list>



        <van-tabbar v-model="active" active-color="#ee0a24" inactive-color="#000">
            <van-tabbar-item icon="home-o" :to="'/'+source+locationSearch">靓号中心</van-tabbar-item>
            <van-tabbar-item icon="search">订单中心</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    import {Dialog} from "vant";

    export default {
        name: "OrderSearch",
        data(){
            return{
                locationSearch:"",//路径？的内容
                source:"",//渠道
                active:1,//导航索引
                userName:"",//姓名
                phone:"",//联系电话
                message:"",//留言
                orderShow:true,//订单查询开关
                orderList:[],//查询用户订单
            }
        },

        //获取路由参数
        created() {
            this.source = this.$route.params.source;
            this.locationSearch = location.search;
        },
        methods:{
            //订单查询
            queryOrder(){
                let userName = this.userName;
                let phone = this.phone;
                if (userName==null || userName ==""){
                    Dialog.alert({
                        message: "请填写姓名"
                    });
                    return;
                }
                if (phone==null || phone ==""){
                    Dialog.alert({
                        message: "请填写联系电话"
                    });
                    return;
                }
                this.sendCodeDisable = true;
                let url = "/haoma52-server/order/queryByCustomer";
                let params = {
                    userName: userName,
                    phone: phone
                };
                this.$axios.post(url, params).then(resp => {
                    if (resp.data.status == this.SUCCESS) {
                        this.orderList = resp.data.data;
                        if (this.orderList.length ==0){
                            Dialog.alert({
                                message: "未查询到订单，请联系客服确认!"
                            });
                        }
                    } else {
                        Dialog.alert({
                            message: resp.data.message
                        });
                    }
                });
        },
        }
    }
</script>

<style scoped>
    .order_query {
        width: 7.5rem;
        margin: 0.8rem auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .order_query img {
        width: 0.9rem;
    }
    .order_query span {
        font-size: 0.4rem;
        margin-left: 0.5rem;
    }
</style>
