<template>
	<div>
		<van-image :src="require('../assets/images/rules/1.png')" style="display: block"></van-image>
		<van-image :src="require('../assets/images/rules/2.png')" style="display: block"></van-image>
		<van-image :src="require('../assets/images/rules/3.png')" style="display: block"></van-image>
		<van-image :src="require('../assets/images/rules/4.png')" style="display: block"></van-image>
		<van-image :src="require('../assets/images/rules/5.png')" style="display: block"></van-image>
		<van-image :src="require('../assets/images/rules/6.jpg')" style="display: block"></van-image>
	</div>
</template>

<script>
export default {
    name: "FangZha"
}
</script>

<style scoped>

</style>