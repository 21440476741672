import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import axios from 'axios'
import VueWechatTitle from 'vue-wechat-title'

import isIdcard from "./assets/js/idcard"



const app = createApp(App)

app.use(router)
app.use(isIdcard)

import {Button, Popup, Form, Field, Cascader, Checkbox, Dialog, CellGroup, Search ,NoticeBar, Swipe, SwipeItem ,Image as VanImage, Icon,Picker ,Loading ,Area ,Col, Row
    ,Tabbar, TabbarItem, Cell, ActionSheet, Divider, Switch, List
} from 'vant';
app.use(Button).use(Popup).use(Form).use(Field).use(Cascader).use(Checkbox).use(Dialog).use(CellGroup).use(Search ).use(NoticeBar ).use(Swipe).use(SwipeItem )
    .use(VanImage).use(Icon).use(Picker ).use(Loading).use(Area).use(Col).use(Row).use(Tabbar).use(TabbarItem).use(Cell).use(ActionSheet).use(Divider).use(Switch).use(List)

app.use(VueWechatTitle)
import 'vant/lib/index.css'

app.mount('#app')
// 定义全局变量
app.config.globalProperties.$axios = axios;
// 服务端返回的执行成功的状态码
app.config.globalProperties.SUCCESS = '0000';