<template>
    <div>
        <div style="margin-top: 30%;">
            <div class="btnOutDiv">
                <van-button type="primary" class="btn" :to="'/search/'+source">查询订单</van-button>
            </div>
            <div class="btnOutDiv">
                <van-button plain  type="primary" class="btn" :to="'/'+source">返回主页</van-button>
            </div>
        </div>
        <div class="imgOutDiv" v-if="customerServiceNumberShow">
            <van-image
                    width="80%"
                    height=auto
                    :src="'https://m.52haoma.com/wximg/'+customerServiceNumber+'.jpg'"
            >
                <template v-slot:error>加载二维码失败，请直接添加微信号：{{customerServiceNumber}}</template>
            </van-image>
        </div>
        <div style="width: 100%;text-align: center;" v-if="customerServiceNumber!='0571-28888520'">
            👆客服微信👆
        </div>
        <div style="width: 100%;text-align: center;" v-else-if="customerServiceNumber=='0571-28888520'">
            👆微信公众号👆
        </div>

    </div>

</template>

<script>

    export default {
        name: "WxPayResult",
    data(){
      return{
          tradeNo:"",//第三方订单号
          orderId:"",//订单id
          source:"",//渠道
          customerServiceNumber:"",//客服联系电话
          customerServiceNumberShow:false,
      }
    },


    //获取路由参数
    created() {
        this.tradeNo = this.$route.params.tradeNo;
        this.source = this.$route.params.source;
        this.queryServer();
    },
    //
    methods:{
        //查询客服热线
        queryServer(){

            let url = '/haoma52-server/customer/queryServiceNumber'
            let source = this.source;
            if (source==null || source==''){
                return;
            }
            let params = {
                "source":source
            }
            // 设置请求参数
            this.$axios.post(url, params).then(resp => {
                if (resp.data.status == this.SUCCESS) {
                    let phone = resp.data.data;
                    if (phone!=null && phone!=''){
                        this.customerServiceNumber = phone;
                        this.customerServiceNumberShow = true;
                        return;
                    }
                }
                this.customerServiceNumber = '0571-28888520';
                this.customerServiceNumberShow = true;
            });
        },
    },

    }
</script>

<style scoped>
    .btnOutDiv{
        width: 100%;
    }
    .btn{
        width: 80%;
        left:10%;
    }
    .imgOutDiv{
        margin-top: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
