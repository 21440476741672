<template>
    <!-- 两端对齐 -->
    <van-row justify="space-between">
        <van-col span="24">
            <van-image style="display: block" :src="require('../assets/images/linkBanner.png')" >
                <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                </template>
            </van-image>
        </van-col>
        <van-col span="24">
            <van-notice-bar
                    text="靓号有保底消费，有在网合约要求，请根据自己的实际消费情况，理性消费。"
                    :left-icon="require('../assets/images/laba.png')"/>
        </van-col>
        <van-col span="24">
            <van-search
                    v-model.tirm=qryObj.phoneNumber
                    show-action
                    shape="round"
                    label="模糊查询"
                    background="#4fc08d"
                    placeholder="请输入搜索关键词">
                <template #action>
                    <div @click="loadData(1)">搜索</div>
                </template>
            </van-search>
        </van-col>
        <van-col span="8">
            <van-cell center title="尾号">
                <template #right-icon>
                    <van-switch v-model=qryObj.trail size="16px"/>
                </template>
            </van-cell>
        </van-col>
        <van-col span="8">
            <van-cell center title="不含4">
                <template #right-icon>
                    <van-switch v-model=this.qryObj.exclude size="16px"/>
                </template>
            </van-cell>
        </van-col>
        <van-col span="8">
            <van-cell center>
                <van-button round type="mini" color="#4169E1" block @click="clear">清空条件</van-button>
            </van-cell>
        </van-col>
    </van-row>

    <van-row justify="space-between">
        <van-col span="6" class="van-hairline--surround" >
            <van-cell is-link arrow-direction="down" @click="this.operatorShow = true" class="van-ellipsis">{{this.operator}}</van-cell>
            <van-action-sheet
                    v-model:show=this.operatorShow
                    description="请选择运营商"
                    :actions=this.operatorActions
                    @select="operatorSelect" />
        </van-col>
        <van-col span="6" class="van-hairline--surround" >
            <van-cell is-link arrow-direction="down" @click="AddressShow = true" class="van-ellipsis">{{this.address}}</van-cell>
            <van-popup v-model:show=AddressShow position="bottom" :style="{ height: '45%' }">
                <van-area
                        title="请选择归属地"
                        :area-list="areaList"
                        :columns-num="2"
                        @confirm="AddressConfirm"
                        @cancel="AddressShow = false"
                />
            </van-popup>
        </van-col>
        <van-col span="6" class="van-hairline--surround" >
            <van-cell is-link arrow-direction="down" @click="this.ruleShow = true" class="van-ellipsis">{{this.rule}}</van-cell>
            <van-popup v-model:show=this.ruleShow position="bottom" :style="{ height: '45%' }">
                <van-cascader
                        title="请选择号码规律"
                        :options="rules"
                        @close="this.ruleShow = false"
                        @finish="onFinish"
                />
            </van-popup>
        </van-col>
        <van-col span="6" class="van-hairline--surround" >
            <van-cell is-link arrow-direction="down" @click="this.priceShow = true" class="van-ellipsis">{{this.price}}</van-cell>
            <van-action-sheet
                    v-model:show=this.priceShow
                    description="请选择价格区间"
                    :actions=this.priceActions
                    @select="priceSelect" />
        </van-col>
    </van-row>
    <van-row justify="space-between">
        <van-col span="24">
            <MyScroll
                    class="wrapper"
                    :data="list"
                    :pulldown="pulldown"
                    @pulldown="loadData(currentPage)"
                    :pullup="pullup"
                    @scrollToEnd="getData(currentPage)">
                <ul class="content">
                    <router-view />
                    <div class="phonelist" v-for="item in list" :key="item" @click="toMarkDowm(item)">
                        <span class="liang_phonenumber" v-html=item.display></span>
                        <span class="liang_flag">靓</span>
                        <span class="price">￥{{item.serviceFee}}</span>
                        <span class="originalPrice">￥{{item.serviceFee * 3}}</span>
                        <span class="opt_Id">
                            <span v-if="item.optId == 1">中国移动</span>
                            <span v-else-if="item.optId == 2">中国联通</span>
                            <span v-else-if="item.optId == 3">中国电信</span>
                            <span v-else-if="item.optId == 4">民营运营商</span>
                        </span>
                        <span class="opt_area">{{item.area}}</span>
                    </div>
                </ul>
            </MyScroll>
            <!-- 搜索提示 -->
            <van-empty image="search" description="暂无数据" v-show=emptyShow class="emptyimage"/>
        </van-col>
    </van-row>
</template>

<script>
    import { areaList } from '@/assets/js/areaList'
    import rules from '../assets/js/rules.json'
    import MyScroll from './MyScroll'
    import {Dialog} from "vant";
    export default {
        name: "LinkIndex.vue",
        setup() {
            return { areaList,rules};
        },
        components:{
            MyScroll
        },
        data(){
            return{
                qryObj:{},//查询对象
                bottomSwitch:false,//标签栏默认底部开关
                operator:"运营商",//运营商
                operatorShow:false,//运营商弹出层开关
                address:"归属地",//归属地
                AddressShow:false,//归属地弹出层开关
                rule:"规律",//规律
                ruleShow:false,//规律弹出层开关
                price:"价格",//价格
                priceShow:false,//价格弹出层开关
                serverShow:false,//客服热线开关
                //运营商选项数据
                operatorActions:[
                    { name: '全部'},
                    { name: '中国移动',value: 1 },
                    { name: '中国联通',value: 2 },
                    { name: '中国电信',value: 3 },
                    { name: '民营运营商',value: 4 },
                ],
                //价格选项数据
                priceActions:[
                    { name: '全部'},
                    { name: '1元专区',value:1},
                    { name: '2~100元',value:2},
                    { name: '101~200元',value:3},
                    { name: '201~300元',value:4},
                    { name: '301~500元',value:5},
                    { name: '501~1000元',value:6},
                    { name: '1001~5000元',value:7},
                    { name: '5001及以上',value:8},
                ],
                pulldown: true,//开启下拉刷新
                pullup:true,//开启上拉加载
                currentPage:1,//当前页
                list:[],//列表数据
                emptyShow:false,//暂无数据提示开关
            }
        },
        created() {
            this.loadData(1)
        },
        methods:{
            //跳转到号码下单页面
            toMarkDowm(item){
                this.$router.push({ path: '/details',
                    query: { phoneNumber: item.phoneNumber,
                        serviceFee: item.serviceFee,
                        area:item.area,
                        province:item.province,
                        optId:item.optId,
                        advanceFee:item.advanceFee} })
            },
            //根据条件查询数据，并覆盖原数据
            loadData(pageNum){
                this.emptyShow = false
                this.loadingShow = true
                let url = '/haoma52-server/index/index-list'
                this.qryObj.pageNum = pageNum
                // 设置请求参数
                let params = { params: this.qryObj};
                this.$axios.post(url,params).then(resp => {
                    if(resp.data.status != this.SUCCESS){
                        Dialog.alert({
                            message: '服务端异常！',
                            theme: 'round-button',
                        })
                        return
                    }
                    this.list = resp.data.list;
                    this.currentPage++;
                });
            },
            //根据条件查询数据，并追加数据
            getData(pageNum){
                this.emptyShow = false
                let url = '/haoma52-server/index/index-list'
                this.qryObj.pageNum = pageNum
                // 设置请求参数
                let params = { params: this.qryObj};
                this.$axios.post(url,params).then(resp => {
                    if(resp.data.status != this.SUCCESS){
                        Dialog.alert({
                            message: '服务端异常！',
                            theme: 'round-button',
                        })
                        return
                    }
                    this.list = this.list.concat(resp.data.list);
                    this.currentPage++;
                });
            },
            //运营商选择
            operatorSelect(item){
                this.operatorShow = false
                this.qryObj.optId = item.value
                if(item.name == "全部"){
                    this.operator = "运营商"
                }else {
                    this.operator = item.name
                }
                this.loadData()
            },
            //地区选择
            AddressConfirm(item){
                this.AddressShow = false
                let p = item[0].name
                let a = item[1].name
                this.address = a
                if(a == ""){
                    this.address = p
                }
                this.qryObj.province = p
                this.qryObj.area = a
                this.loadData()
            },
            //规律选择
            onFinish(item){
                this.ruleShow = false
                let p = item.selectedOptions[0].text
                let a = item.selectedOptions[1].text
                this.rule = p + "-" + a
                this.qryObj.pType = p
                this.qryObj.aType = a
                this.loadData()
            },
            //价格选择
            priceSelect(item){
                this.priceShow = false
                this.price = item.name
                this.qryObj.price = item.value
                this.loadData()
            },
            //清空条件
            clear(){
                this.operator = "运营商"
                this.address = "归属地"
                this.rule = "规律"
                this.price = "价格"
                this.qryObj = {}
                this.loadData()
            }
        },
        watch:{
            //监视查询对象，如有变化则将当前页设为1
            qryObj(){
                this.currentPage = 1
            },
            list(value){
                if(value.length == 0){
                    this.emptyShow=true
                    this.currentPage = 1
                }
            }
        }
    }
</script>

<style scoped>
    .wrapper {
        width: 100%;
        height: 50%;
        background:#F5F5F5;
        position: absolute;
        overflow: hidden;
    }
    .wrapper .content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .emptyimage{
        width: 100%;
        height: 45%;
        position: absolute;
    }
    .phonelist{
        width: 3.55rem;
        height: 1.8rem;
        background: #ffffff;
        border-radius: 0.05rem;
        margin: 0.1rem;
        text-decoration: none;
        display: flex;
        flex-wrap: wrap;
        align-content: space-around;
    }
    .liang_phonenumber{
        font-size: 0.39rem;
        text-align: center;
        width: 80%;
        color: #292826;
        font-weight: bold;
    }
    .liang_flag{
        font-size: 0.3rem;
        display: block;
        width: 0.4rem;
        height: 0.4rem;
        text-align: center;
        background-color: red;
        color: #ffffff;
    }
    .opt_area{
        text-align: right;
        width: 40%;
        color: #B8B8B8FF;
        font-size: 0.28rem;
        height: 0.42rem;
        margin-right: 0.3rem;
    }
    .opt_Id{
        text-align: center;
        width: 40%;
        color: #B8B8B8FF;
        font-size: 0.28rem;
        height: 0.42rem;
        margin-right: 0.2rem;
        margin-left: 0.2rem;
    }
    .price{
        display: block;
        width: 30%;
        font-size: 0.28rem;
        color: red;
        text-align: left;
        margin-left: 0.2rem;
        margin-right: 1rem;
    }
    .originalPrice{
        display: block;
        width: 30%;
        font-size: 0.28rem;
        color: #B8B8B8FF;
        text-align: center;
        text-decoration:line-through;
    }
</style>