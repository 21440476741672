<template>
    <div>
        <div class="order_query">
            <img :src="require('../assets/images/order_big_tg.png')" >
            <span>个性定制</span>
        </div>
        <van-form @submit="submitCustomize">
            <van-cell-group inset>
                <van-field
                        v-model="userName"
                        name="用户名"
                        label="姓名"
                        placeholder="请填写购买人姓名"
                        :rules="[{ required: true, message: '请填写姓名' }]"
                />
                <van-field
                        v-model="phone"
                        type="tel"
                        name="联系电话"
                        label="联系电话"
                        placeholder="请填写购买人联系电话"
                        :rules="[{ required: true, message: '请填写联系电话' }]"
                />
                <van-field
                        v-model="message"
                        rows="3"
                        autosize
                        label="留言"
                        type="textarea"
                        maxlength="120"
                        placeholder="我想要杭州尾号3个8的号码，预算800左右"
                        show-word-limit
                />
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">
                    个性定制
                </van-button>
            </div>
        </van-form>

        <van-tabbar v-model="active" active-color="#ee0a24" inactive-color="#000">
            <van-tabbar-item icon="home-o" :to="'/'+source+locationSearch">靓号中心</van-tabbar-item>
            <van-tabbar-item icon="search">个性定制</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    import {Dialog} from "vant";

    export default {
        name: "OrderSearch",
        data(){
            return{
                locationSearch:"",//路径？的内容
                source:"",//渠道
                active:1,//导航索引
                userName:"",//姓名
                phone:"",//联系电话
                message:"",//留言
            }
        },
        //获取路由参数
        created() {
            this.source = this.$route.params.source;
            this.locationSearch = location.search;
        },
        methods:{
            //提交订单
            submitCustomize(){
                let name = this.userName;
                let phone = this.phone;
                let request = this.message;
                let source = this.source;
                if (name==null || name ==""){
                    Dialog.alert({
                        message: "请填写姓名"
                    });
                    return;
                }
                if (phone==null || phone ==""){
                    Dialog.alert({
                        message: "请填写联系电话"
                    });
                    return;
                }
                if (request==null || request ==""){
                    Dialog.alert({
                        message: "请填写留言"
                    });
                    return;
                }

                this.sendCodeDisable = true;
                let url = "/haoma52-server/customize/submitCustomize";
                let params = {
                    name: name,
                    phone: phone,
                    request:request,
                    resource:source,
                };
                this.$axios.post(url, params).then(resp => {
                    if (resp.data.status == this.SUCCESS) {
                            Dialog.alert({
                                message: "您的需求提交成功！"
                            });
                        //清除表单信息
                        this.clearForm();
                    } else {
                        Dialog.alert({
                            message: resp.data.message
                        });
                    }
                });
            },
            //清除表单信息
            clearForm(){
                this.userName="";
                this.phone="";
                this.message="";
            }
        }
    }
</script>

<style scoped>
    .order_query {
        width: 7.5rem;
        margin: 0.8rem auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .order_query img {
        width: 0.9rem;
    }
    .order_query span {
        font-size: 0.4rem;
        margin-left: 0.5rem;
    }
</style>
