<template>
  <div>
    <!-- 两端对齐 -->
    <van-row justify="space-between">
      <van-col span="24">
        <van-image style="display: block" :src="require('../assets/images/banner_index.jpg')">
          <template v-slot:loading>
            <van-loading type="spinner" size="20"/>
          </template>
        </van-image>
      </van-col>
      <van-col span="24">
        <van-notice-bar
            text="靓号有保底消费，有在网合约要求，请根据自己的实际消费情况，理性消费。"
            :left-icon="require('../assets/images/laba.png')"/>
      </van-col>
      <van-col span="24">
        <router-view/>
        <van-tabbar route :fixed=bottomSwitch>
          <van-tabbar-item replace :icon="require('../assets/images/lh_center.png')" :to="'/'+source+locationSearch">靓号中心</van-tabbar-item>
          <van-tabbar-item replace :icon="require('../assets/images/customize.png')" :to="'/customize/'+source+locationSearch">个性定制</van-tabbar-item>
          <van-tabbar-item replace :icon="require('../assets/images/hui.png')" :to="'/change/'+source+locationSearch"><span style="color:red;font-weight: bold">改价专区</span></van-tabbar-item>
          <van-tabbar-item replace :icon="require('../assets/images/service_center.png')" @click="queryServer">客服联系</van-tabbar-item>
          <van-tabbar-item replace :icon="require('../assets/images/order_query.png')" :to="'/search/'+source+locationSearch">订单查询</van-tabbar-item>

          <van-popup v-model:show="serverShow" position="bottom" :style="{ height: '45%' }">
            <div style="flex: content;flex-wrap: wrap">
              <h2 style="font-size: 0.36rem;text-align: center;margin: 0.3rem auto;">客服热线</h2>
              <span class="red" style="display:block;text-align: center;font-size: 0.32rem;">{{customerServiceNumber}}</span>
            </div>
          </van-popup>

        </van-tabbar>
      </van-col>
    </van-row>
    <div style="margin-top:0.1rem;margin-bottom: 1.56rem">

      <van-list v-model="loading" :finished="finished" :finished-text="result" @load="loadData">
        <article class="phone_number_article">

          <div class="phonelist" v-for="item in list" :key="item" @click="showPopup(item)">
            <span class="liang_phonenumber" v-html=item.display></span>
            <span class="opt_area">{{ item.province }}&nbsp;{{ item.area }}</span>
            <div class="priceAll">
              <span class="price">￥{{ item.changePrice}}</span>
              <span class="originalPrice">￥{{ item.serviceFee * 3 + 288 }}</span>
            </div>
            <div class="detailTime">
              <span>优惠截止
                <span>{{item.showTime}}</span>
              </span>
            </div>
            <div class="detailBottom">
                <span class="downMoney">降{{ item.serviceFee * 3 + 288 - item.changePrice}}</span>
                <span class="buy">马上抢</span>
            </div>

          </div>


        </article>


      </van-list>

    </div>




    <!--下单 -->
    <van-popup v-model:show="show" position="bottom" >
      <div class="order_div">
        <div class="subject1">
          <div class="liang_phonenumber1">{{ phoneObj.phoneNumber }}</div>
          <div class="text-area1"></div>
          <span class="text1">{{ phoneObj.province }}</span>
          <span class="text1">{{ phoneObj.area }}</span>
          <span class="text1" v-if="phoneObj.optId == 1">中国移动</span>
          <span class="text1" v-else-if="phoneObj.optId == 2">中国联通</span>
          <span class="text1" v-else-if="phoneObj.optId == 3">中国电信</span>
          <span class="text1" v-else-if="phoneObj.optId == 4">民营运营商</span>
        </div>
        <div class="subject_b1">
          <div class="service_fee1">
            <span class="text_a1">服务费</span>
            <span class="price1">{{ phoneObj.changePrice }}元</span>
          </div>
          <div class="service_fee1 border1">
            <span class="text_a1">代收预存款</span>
            <span class="price1" v-if="phoneObj.advanceFee == ''||phoneObj.advanceFee == null">0.0元</span>
            <span class="price1" v-else>{{ phoneObj.advanceFee }}元</span>
          </div>
        </div>
        <div class="text_b1">
          <p>{{ phoneObj.introduce }}</p>
          <div class="order_info1">填写订单信息马上抢购</div>
        </div>
        <van-form @submit="onSubmit">
          <van-cell-group inset>
            <van-field
                v-model="orderObj.userInfo.userName"
                name="姓名"
                label="真实姓名"
                placeholder="请输入开户人真实姓名"
                :rules="[{ required: true, message: '请正确填写真实姓名', validator: nameValidator }]"
            />
            <van-field
                v-model="orderObj.userInfo.idCard"
                name="身份证号"
                label="身份证号"
                placeholder="请输入开户人真实身份证"
                :rules="[{ required: true, message: '请正确填写身份证号', validator: idCardValidator}]"
            />
            <van-field
                is-link
                readonly
                name="area"
                label="地区选择"
                v-model:placeholder="areaStr"
                @click="showArea = true"
            />
            <van-popup v-model:show="showArea" position="bottom">
              <van-area
                  :area-list="areaList"
                  @confirm="onConfirm"
                  @cancel="showArea = false"
              />
            </van-popup>
            <van-field
                v-show="showAddress"
                v-model="orderObj.userInfo.address"
                label="详细地址"
                name="详细地址"
                placeholder="请输入详细地址"
                :rules="[{ required: true, message: '请输入正确的详细地址' }]"
            />
            <van-field
                v-model="orderObj.userInfo.phone"
                type="tel"
                name="联系电话"
                label="联系电话"
                placeholder="请输入开户人真实联系电话"
                :rules="[{ required: true, message: '请正确填写联系电话', validator: phoneValidator}]"
            />
            <van-field
                v-model="orderObj.code"
                name="验证码"
                type="number"
                label="验证码"
                placeholder="请输入验证码"
                :rules="[{ required: true, message: '请正确填写验证码' }]">
              <template #button>
                <van-button size="small" type="primary" :disabled="sendCodeDisable" @click="sendCode()">
                  {{ sendCodeText }}
                </van-button>
              </template>
            </van-field>
            <van-field name="checkbox" :rules="[{ required: true, message: '请阅读并勾选' }]">
              <template #input>
                <van-checkbox v-model="orderObj.checked" shape="square">我已阅读并同意</van-checkbox>
              </template>
              <template #right-icon>
                <span style="color: cornflowerblue" @click="protocolShow = true">《关于客户个人信息收集、<br>使用规则的公告》</span>
              </template>
            </van-field>

            <van-field name="price">
              <template #input>
                在线合计金额
              </template>
              <template #right-icon>
                <span style="color: red;font-weight:bold;">合计{{ phoneObj.priceTotal }}元</span>
              </template>
            </van-field>
          </van-cell-group>
          <div style="margin: 0.16rem;margin-bottom:0.5rem;">
            <van-button round block type="primary" native-type="submit" color="#4fc08d">
              立即微信下单
            </van-button>
          </div>
        </van-form>
      </div>
    </van-popup>


    <van-popup v-model:show="protocolShow" round position="bottom" :style="{ height: '100%' }">
      <div class="popup1">
        <div class="content">
          <h2>关于客户个人信息收集、使用规则的公告</h2>
          <div style="max-height: 22rem">
            <p style="text-indent: 0">尊敬的客户：</p>
            <p>
              根据《中华人民共和国反恐怖主义法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在中国联合网络通信有限公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等）办理固定电话、移动电话（含无线上网卡）入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效证件原件并进行查验、登记，登记信息包括姓名、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。
            </p>
            <p>如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。</p>
            <p>
              为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息：
            </p>
            <p>(a)事先获得客户的明确授权；</p>
            <p>(b)根据有关的法律法规要求；</p>
            <p>(c)按照相关司法机关和/或政府主管部门的要求；</p>
            <p>(d)为维护社会公众的利益所必需且适当；</p>
            <p>(e)为维护我公司或客户的合法权益所必需且适当。</p>
            <p>中国联合网络通信有限公司</p>
            <br>
            <van-button plain hairline type="primary" @click="protocolShow = false" block
                        style="margin: 0 auto !important;">我已阅读
            </van-button>
            <br>
            <br>
            <br>
            <br>
          </div>
        </div>
      </div>
    </van-popup>

    <div style="width: 7.5rem; height: 0.1rem; margin: 0 auto; position: fixed; bottom: 0"></div>


    <div class="goTop" @click="backtop">
      <van-icon name="back-top"/>
    </div>
  </div>

</template>

<script>
import {areaList} from '@/assets/js/areaList'
import rules from '../assets/js/rules.json'

import {Dialog} from 'vant';
import isIdcard from "@/assets/js/idcard";
import {ref} from 'vue';

export default {

  name: 'MainIndex',
  setup() {
    // eslint-disable-next-line no-undef
    //弹出层页面是否显示
    const show = ref(false);
    //存放办理号码的相关信息
    const phoneObj = {};

    //点击弹出层调用的方法
    const showPopup = (item) => {
      show.value = true;
      //下单弹窗所需参数

      phoneObj.phoneNumber = item.phoneNumber;
      phoneObj.serviceFee = item.serviceFee;
      phoneObj.optId = item.optId;
      phoneObj.area = item.area;
      phoneObj.province = item.province;
      phoneObj.advanceFee = item.advanceFee;
      phoneObj.changePrice = item.changePrice;
      phoneObj.priceTotal = parseInt(phoneObj.changePrice) + parseInt(phoneObj.advanceFee);
      phoneObj.introduce = item.introduce;

    };
    return {
      areaList,
      rules,
      show,
      showPopup,
      phoneObj,
    };
  },

  data() {
    return {
      locationSearch:"",//路径？的内容
      customerServiceNumber:"",//客服热线电话
      source:"",//渠道
      qryObj: {},//查询对象
      bottomSwitch: false,//标签栏默认底部开关
      operator: "运营商",//运营商
      operatorShow: false,//运营商弹出层开关
      address: "归属地",//归属地
      AddressShow: false,//归属地弹出层开关
      rule: "规律",//规律
      ruleShow: false,//规律弹出层开关
      price: "价格",//价格
      priceShow: false,//价格弹出层开关
      serverShow: false,//客服热线开关
      //运营商选项数据
      operatorActions: [
        {name: '全部'},
        {name: '中国移动', value: 1},
        {name: '中国联通', value: 2},
        {name: '中国电信', value: 3},
        {name: '民营运营商', value: 4},
      ],
      //价格选项数据
      priceActions: [
        {name: '全部'},
        {name: '1元专区', value: 1},
        {name: '2~100元', value: 2},
        {name: '101~200元', value: 3},
        {name: '201~300元', value: 4},
        {name: '301~500元', value: 5},
        {name: '501~1000元', value: 6},
        {name: '1001~5000元', value: 7},
        {name: '5001及以上', value: 8},
      ],
      pulldown: true,//开启下拉刷新
      pullup: true,//开启上拉加载
      currentPage: 1,//当前页
      list: [],//列表数据
      emptyShow: false,//暂无数据提示开关
      //下单
      orderObj: {
        changeFlag:true,//是否为改价号码
        phoneNumber: "",//选购号码
        code: "",//验证码
        checked: true,//已阅读协议
        customerName: "",//渠道
        clickid: "",//事件id
        costPrice: 0,//零售价
        changePrice:0,//改价
        advanceFee: 0,//预存话费
        userInfo: {
          userName: "",//真实姓名
          idCard: "",//身份证号
          phone: "",//联系电话
          province: "",//省
          city: "",//市
          area: "",//区
          address: ""//详细地址
        }
      },
      areaStr: "点击选择省市区",//地址
      showArea: false,//省市区开关
      showAddress: false,//详细地址开关
      protocolShow: false,//协议弹出层开关
      // 发送短信按钮
      sendCodeDisable: false,
      sendCodeText: '发送短信',
      seconds: 60,
      loading: false,
      finished: false,
      result: ""
    }
  },
  computed: {},
  created() {
    this.source = this.$route.params.source;
    this.loadData(1);
    this.locationSearch = location.search;
  },
  methods: {
    //查询客服热线
    queryServer(){
      this.serverShow = true;

      let url = '/haoma52-server/customer/queryServiceNumber'
      let source = this.source;
      if (source==null || source==''){
        return;
      }
      let params = {
        "source":source
      }
      // 设置请求参数
      this.$axios.post(url, params).then(resp => {
        if (resp.data.status == this.SUCCESS) {
          let phone = resp.data.data;
          if (phone!=null && phone!=''){
            this.customerServiceNumber = phone;
            return;
          }
        }
        this.customerServiceNumber = '0571-28888520';
      });
    },
    //刷新主页
    reLoadIndex() {
      location.reload();
    },
    backtop() {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30)
    },
    //根据条件查询数据，并覆盖原数据
    loadData(pageNum) {
      // 如果上一次还没加载完成，停止下一次加载
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.result = "";

      if(pageNum){
        this.currentPage = pageNum;
      }

      if(this.currentPage == 1){
        this.list = [];
      }



      let url = '/haoma52-server/index/change-list'
      this.qryObj.pageNum = this.currentPage;
      //渠道
      this.qryObj.resource = this.source;
      // 设置请求参数

      this.$axios.post(url, this.qryObj).then(resp => {
        if (resp.data.status != this.SUCCESS) {
          Dialog.alert({
            message: '服务端异常！',
            theme: 'round-button',
          })
          return;
        }
        let list = resp.data.list;
        if (list.length == 0) {
          if (this.currentPage == 1) {
            this.result = "没有更多特惠靓号了，敬请期待！";
          } else {
            this.result = "没有更多了，我是有底线的";
          }
          this.finished = true;
        } else {
          for (let i = 0; i < list.length; i++) {
            this.list.push(list[i]);
          }
          if (list.length < 12) {
            this.result = "没有更多了，我是有底线的";
            this.finished = true;
          } else {
            this.currentPage++;
          }
        }
        this.loading = false;

      });
    },
    //根据条件查询数据，并追加数据
    getData(pageNum) {
      this.emptyShow = false
      let url = '/haoma52-server/index/change-list'
      this.qryObj.pageNum = pageNum;
      //渠道
      this.qryObj.resource = this.source;
      // 设置请求参数
      let params = {params: this.qryObj};
      this.$axios.post(url, params).then(resp => {
        if (resp.data.status != this.SUCCESS) {
          Dialog.alert({
            message: '服务端异常！',
            theme: 'round-button',
          })
          return
        }
        this.list = this.list.concat(resp.data.list);
        this.currentPage++;
      });
    },
    //运营商选择
    operatorSelect(item) {
      this.operatorShow = false
      this.qryObj.optId = item.value
      if (item.name == "全部") {
        this.operator = "运营商"
      } else {
        this.operator = item.name
      }
      this.loadData()
    },
    //地区选择
    AddressConfirm(item) {
      this.AddressShow = false
      let p = item[0].name
      let a = item[1].name
      this.address = a
      if (a == "") {
        this.address = p
      }
      this.qryObj.province = p
      this.qryObj.area = a
      this.loadData()
    },
    //规律选择
    onFinish(item) {
      this.ruleShow = false
      let p = item.selectedOptions[0].text
      let a = item.selectedOptions[1].text
      this.rule = p + "-" + a
      this.qryObj.pType = p
      this.qryObj.aType = a
      this.loadData()
    },
    //价格选择
    priceSelect(item) {
      this.priceShow = false
      this.price = item.name
      this.qryObj.price = item.value
      this.loadData()
    },
    //清空条件
    clear() {
      this.operator = "运营商"
      this.address = "归属地"
      this.rule = "规律"
      this.price = "价格"
      this.qryObj = {}
      this.loadData()
    },

    // 下单
    // 验证身份证号码的合法性
    idCardValidator(val) {
      let r = isIdcard(val);
      if (!r) {
        return "身份证号码不合法，请填写正确的身份证号码";
      }
    },
    // 验证联系电话的合法性
    phoneValidator(val) {
      if (!/^1[3456789]\d{9}$/.test(val)) {
        return "请填写正确的联系电话";
      }
    },
    // 验证姓名的合法性
    nameValidator(val) {
      if (!/^[\u4e00-\u9fa5·]{2,16}$/.test(val)) {
        return "请填写正确的开户人姓名";
      }
    },

    // 检测联系号码填写是否正确
    checkPhone() {
      return /^1[3456789]\d{9}$/.test(this.orderObj.userInfo.phone);
    },
    // 发送短信验证码
    sendCode() {
      if (this.checkPhone()) {
        this.sendCodeDisable = true;
        let url = "/haoma52-server/index/send-code";
        let params = {
          phoneNumber: this.phoneObj.phoneNumber,
          phone: this.orderObj.userInfo.phone
        };
        this.$axios.post(url, params).then(resp => {
          if (resp.data.status == this.SUCCESS) {
            // 开始倒计时
            this.startCount();
          } else {
            Dialog.alert({
              message: resp.data.message
            });
          }
        });
      }
    },
    // 开启倒计时 60s
    startCount() {
      if (this.seconds > 0) {
        this.sendCodeText = this.seconds-- + "后再次发送"
        let _this = this;
        setTimeout(function () {
          _this.startCount()
        }, 1000);
      } else {
        this.sendCodeDisable = false;
        this.sendCodeText = "发送验证码";
        this.seconds = 60;
      }

    },

    //省市区确定回调函数
    onConfirm(item) {
      this.orderObj.userInfo.province = item[0].name
      this.orderObj.userInfo.city = item[1].name
      this.orderObj.userInfo.area = item[2].name
      this.areaStr = item[0].name + "-" + item[1].name + "-" + item[2].name;
      this.showArea = false
      this.showAddress = true
    },
    //表单提交回调函数
    onSubmit() {
      // 将当前请求的地址进行编码后传入到服务端
      this.orderObj.link = encodeURIComponent(window.location.href)

      this.orderObj.customerName = this.$route.params.source;
      if (this.$route.query.clickid) {//头条
        this.orderObj.clickid = this.$route.query.clickid;
      } else if (this.$route.query.gdt_vid) {//广点通
        this.orderObj.clickid = this.$route.query.gdt_vid;
      } else if (this.$route.query.landingid) {
        this.orderObj.clickid = this.$route.query.landingid;
      } else if (this.$route.query.callback) {//快手
        this.orderObj.clickid = this.$route.query.callback;
      }
      //下单提交所需参数
      this.orderObj.phoneNumber = this.phoneObj.phoneNumber;
      this.orderObj.costPrice = this.phoneObj.serviceFee;
      this.orderObj.changePrice = this.phoneObj.changePrice;
      this.orderObj.advanceFee = this.phoneObj.advanceFee;

      let url = "/haoma52-server/order/submitOrder";
      let params = this.orderObj;
      this.$axios.post(url, params).then(resp => {
        if (resp.data.status == this.SUCCESS) {
          //成功
          //关闭弹出框
          this.show = false;
          let costPrice = this.orderObj.costPrice;
          let advanceFee = this.orderObj.advanceFee;
          //清除表单参数
          this.clearOrderObj();
          //如果号码没预存没价格，就不用唤醒微信支付了
          if ((costPrice + advanceFee) > 0) {
            let tradeNo = resp.data.data;
            //唤醒支付
            var ua = window.navigator.userAgent.toLowerCase();
            if (ua.indexOf('micromessenger') != -1) {
              // 微信浏览器
              // window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx0e48b3d222d14003&redirect_uri=https%3A%2F%2Fm.52haoma.com%2Fwxpay%2F"+no+"&response_type=code&scope=snsapi_base#wechat_redirect";
            } else {
              //跳转页面
              this.$router.push({
                path: '/WxPay/'+this.$route.params.source+"/"+tradeNo,
              })
            }
          } else {
            Dialog.alert({
              message: "下单成功!"
            });

          }
        } else {
          //失败
          if (resp.data.message != null && resp.data.message != '') {
            Dialog.alert({
              message: resp.data.message
            });
          } else {
            Dialog.alert({
              message: "下单异常，请联系客服"
            });
          }

        }
      });

    },
    clearOrderObj() {
      this.orderObj = {
        changeFlag:true,//是否为改价号码
        phoneNumber: "",//选购号码
        code: "",//验证码
        checked: true,//已阅读协议
        customerName: "",//渠道
        clickid: "",//事件id
        costPrice: 0,//零售价
        changePrice:0,//改价
        advanceFee: 0,//预存话费
        userInfo: {
          userName: "",//真实姓名
          idCard: "",//身份证号
          phone: "",//联系电话
          province: "",//省
          city: "",//市
          area: "",//区
          address: ""//详细地址
        }
      }
    }
  },

}
</script>

<style scoped>
.order_div{
  width: 6.9rem;
  padding: 0.2rem;
  margin: 0 auto;
  background: #ffffff;
  font-size: 0.25rem !important;
}
.goTop {
  position: fixed !important;
  left: 0.1rem;
  bottom: 2rem;
  z-index: 999;
  font-size: 0.5rem;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  background: #1989FA;
  border-radius: 1rem;
  opacity: 0.2;
}

.phone_number_article {
  width: 7.5rem;
  margin: 0.05rem auto;
  display: flex;
  flex-wrap: wrap;
  background: #f3f4f7;
}

.phone_number_article section {
  width: 3.55rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0.05rem;
  margin-left: 0.08rem;
  margin-top: 0.08rem;
  margin-bottom: 0.08rem;
  text-align: center;
  background: #ffffff;

}

.phone_number_span {
  font-size: 0.35rem;
  width: 3.55rem;
  text-align: center;
  font-weight: bold;
}

.area_span {
  width: 3.55rem;
  text-align: center;
  color: #888888;
  font-size: 0.25rem;
  margin: 0.05rem auto;
}

.price_opt_span {
  width: 3.55rem;
  display: flex;
  justify-content: space-around;
  font-size: 0.3rem;
}

.wrapper {
  width: 7.5rem;
  background: #F5F5F5;
  overflow: hidden;
}

.wrapper .content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.emptyimage {
  width: 100%;
  height: 45%;
  position: absolute;
}

.phonelist {
  width: 3.55rem;
  height: 2.5rem;
  background: #ffffff;
  border-radius: 0.05rem;
  margin: 0.1rem;
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  padding: 0.2rem 0;
}

.liang_phonenumber {
  font-size: 0.42rem;
  text-align: center;
  width: 80%;
  color: #292826;
  font-weight: bold;
}

.liang_flag {
  font-size: 0.3rem;
  display: block;
  width: 0.4rem;
  height: 0.4rem;
  text-align: center;
  background-color: red;
  color: #ffffff;
}

.opt_area {
  text-align: center;
  width: 100%;
  color: #B8B8B8FF;
  font-size: 0.28rem;
  height: 0.42rem;
}

.price {
  display: block;
  font-size: 0.38rem;
  color: red;
  text-align: left;
  margin-left: 2%;
  padding: 2% 10%;
}

.originalPrice {
  display: block;
  font-size: 0.28rem;
  color: #B8B8B8FF;
  text-align: right;
  margin-right: 2%;
  padding: 2% 10%;
  text-decoration: line-through;
}
.detailTime{
  width: 100%;
  font-size: 0.28rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0.1rem auto;
}
.priceAll{
  width: 100%;
  font-size: 0.28rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.downMoney{
  text-align: center;
  padding: 2% 1%;
  border-radius: 0.2rem;
  border: 1px solid #F5A54B;
  color: #F5A54B;
  width:40%;
  margin:auto;
}
.buy{
  text-align: center;
  padding: 2% 1%;
  border-radius: 0.2rem;
  background: red;
  color: white;
  width:40%;
  margin:auto;
}
.detailBottom{
  width: 100%;
  font-size: 0.28rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}




/*下单*/
.nav1 {
  display: block;
  color: #323233;
  font-size: 0.16rem;
  font-family: Open Sans, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB, Microsoft Yahei, sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.8rem;
  background-color: #fff;
  margin: 0.1rem auto;
}

.nav-title1 {
  display: block;
  font-weight: 600;
  font-size: 0.36rem;
  text-transform: capitalize;
}

.nav_back1 {
  display: block;
  position: absolute;
  top: 0.16rem;
  left: 0.16rem;
  width: 0.5rem;
  height: 0.5rem;
  cursor: pointer;
}

.subject1 {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #CB0101;
}

.subject_b1 {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.liang_phonenumber1 {
  font-size: 0.6rem;
  text-align: center;
  line-height: 0.8rem;
  width: 100%;
  color: #ffffff;
  font-weight: bold;
  margin-top: 0.3rem;
}

.copy_button1 {
  background-color: white;
  width: 30%;
  height: 0.6rem;
  margin: 0.1rem auto;
  font-size: 0.24rem;
  text-align: center;
  color: red;
  border: solid 0;
  border-radius: 0.3rem;
}

.text-area1 {
  width: 100%;
}

.text1 {
  display: block;
  width: 1.6rem;
  height: 1rem;
  font-size: 0.3rem;
  text-align: center;
  line-height: 0.8rem;
  color: #ffffff;
}

.text_a1 {
  display: block;
  width: 100%;
  height: 40%;
  font-size: 0.3rem;
  text-align: center;
}

.text_b1 {
  display: block;
  width: 98%;
  height: 2rem;
  font-size: 0.27rem;
  text-align: left;
  line-height: 0.4rem;
  margin: 0 auto;
  margin-top: 0.2rem;
  border-top: solid 0.01rem #B8B8B8FF;
}

.service_fee1 {
  width: 45%;
  height: 0.8rem;
  margin-top: 0.2rem;
}

.border1 {
  border-left: solid 0.01rem #B8B8B8FF;
}

.price1 {
  display: block;
  width: 100%;
  height: 40%;
  font-size: 0.3rem;
  text-align: center;
  color: #F5A54B;
  margin: 0.1rem auto;
}

.order_info1 {
  width: 100%;
  margin: 0.1rem auto;
  height: 0.6rem;
  line-height: 0.6rem;
  background: #CB0101;
  color: #ffffff;
  font-size: 0.3rem;
  text-align: center;
  border-radius: 0.6rem;
}

.infoitem1 {
  width: 7rem;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 0.05rem 0.1rem;
  margin: 0 auto;
}

.info_content1 {
  display: flex;
  font-size: 0.28rem;
  flex-wrap: wrap;
}

.popup1 {
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow-y: auto;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  font-family: 微软雅黑;
  font-size: 0.22rem;
}

.content1 {
  width: 90%;
  margin: 0 auto;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

.content1 p {
  text-indent: 2em;
}

.content1 h2 {
  display: block;
  font-size: 0.36rem;
  margin-block-start: 0.83rem;
  margin-block-end: 0.83rem;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
  text-align: center;
}
</style>
