<template>
    <div>
        <div class="nav">
            <div class="nav-title">返回继续选号</div>
            <img class="nav_back" :src="require('../assets/images/arrow-left-bold.png')" @click="back"/>
        </div>
        <div class="subject">
            <div class="liang_phonenumber">{{phoneObj.phoneNumber}}</div>
            <button class="copy_button" type="button" @click="copyPhoneNumber">复制号码后8位</button>
            <div class="text-area"></div>
            <span class="text">{{phoneObj.province}}</span>
            <span class="text">{{phoneObj.area}}</span>
            <span class="text" v-if="phoneObj.optId == 1">中国移动</span>
            <span class="text" v-else-if="phoneObj.optId == 2">中国联通</span>
            <span class="text" v-else-if="phoneObj.optId == 3">中国电信</span>
            <span class="text" v-else-if="phoneObj.optId == 4">民营运营商</span>
        </div>
        <div class="subject_b">
            <div class="service_fee">
                <span class="text_a">服务费</span>
                <span class="price">{{phoneObj.serviceFee}}元</span>
            </div>
            <div class="service_fee border">
                <span class="text_a">代收预存款</span>
                <span class="price" v-if="phoneObj.advanceFee == ''||phoneObj.advanceFee == null">0.0元</span>
                <span class="price" v-else>{{phoneObj.advanceFee}}元</span>
            </div>
        </div>
        <div class="text_b">
            <p>靓号月保底129元2年，立即到账0元话费，开户5G畅爽冰激凌129元（30G通用流量+500分钟通话）套餐，激活扣除本月月费(接受个性定制)</p>
            <div class="order_info">填写订单信息马上抢购</div>
        </div>
        <van-form @submit="onSubmit">
            <van-cell-group inset>
                <van-field
                        v-model="orderObj.userInfo.userName"
                        name="姓名"
                        label="真实姓名"
                        placeholder="请输入开户人真实姓名"
                        :rules="[{ required: true, message: '请正确填写真实姓名', validator: nameValidator }]"
                />
                <van-field
                        v-model="orderObj.userInfo.idCard"
                        name="身份证号"
                        type="digit"
                        label="身份证号"
                        placeholder="请输入开户人真实身份证号"
                        :rules="[{ required: true, message: '请正确填写身份证号', validator: idCardValidator}]"
                />
                <van-field
                        is-link
                        readonly
                        name="area"
                        label="地区选择"
                        v-model:placeholder="areaStr"
                        @click="showArea = true"
                />
                <van-popup v-model:show="showArea" position="bottom">
                    <van-area
                            :area-list="areaList"
                            @confirm="onConfirm"
                            @cancel="showArea = false"
                    />
                </van-popup>
                <van-field
                        v-show="showAddress"
                        v-model="orderObj.userInfo.address"
                        label="详细地址"
                        name="详细地址"
                        placeholder="请输入详细地址"
                        :rules="[{ required: true, message: '请输入正确的详细地址' }]"
                />
                <van-field
                        v-model="orderObj.userInfo.phone"
                        type="tel"
                        name="联系电话"
                        label="联系电话"
                        placeholder="请输入开户人真实联系电话"
                        :rules="[{ required: true, message: '请正确填写联系电话', validator: phoneValidator}]"
                />
                <van-field
                        v-model="orderObj.code"
                        name="验证码"
                        type="number"
                        label="验证码"
                        placeholder="请输入验证码"
                        :rules="[{ required: true, message: '请正确填写验证码' }]">
                    <template #button>
                        <van-button size="small" type="primary"  :disabled="sendCodeDisable" @click="sendCode()">{{sendCodeText}}</van-button>
                    </template>
                </van-field>
                <van-field name="checkbox" :rules="[{ required: true, message: '请阅读并勾选' }]">
                    <template #input>
                            <van-checkbox v-model="orderObj.checked" shape="square" />
                    </template>
                    <template #right-icon >
                        我已阅读并同意<span style="color: cornflowerblue" @click="protocolShow = true">《关于客户个人信息收集、<br>使用规则的公告》</span>
                    </template>
                </van-field>
                <van-popup v-model:show="protocolShow"  round position="bottom" :style="{ height: '100%' }">
                    <div class="popup">
                        <div class="content">
                            <h2>关于客户个人信息收集、使用规则的公告</h2>
                            <div style="max-height: 22rem">
                                <p style="text-indent: 0">尊敬的客户：</p>
                                <p>
                                    根据《中华人民共和国反恐怖主义法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在中国联合网络通信有限公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等）办理固定电话、移动电话（含无线上网卡）入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效证件原件并进行查验、登记，登记信息包括姓名、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。
                                </p>
                                <p>如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。</p>
                                <p>
                                    为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息：
                                </p>
                                <p>(a)事先获得客户的明确授权；</p>
                                <p>(b)根据有关的法律法规要求；</p>
                                <p>(c)按照相关司法机关和/或政府主管部门的要求；</p>
                                <p>(d)为维护社会公众的利益所必需且适当；</p>
                                <p>(e)为维护我公司或客户的合法权益所必需且适当。</p>
                                <p>中国联合网络通信有限公司</p>
                                <br>
                                <van-button plain hairline type="primary" @click="protocolShow = false" block style="margin: 0 auto !important;">我已阅读</van-button>
                                <br>
                                <br>
                                <br>
                                <br>
                            </div>
                        </div>
                    </div>
                </van-popup>
                <van-field name="price" >
                    <template #input>
                        在线合计金额
                    </template>
                    <template #right-icon >
                        <span style="color: red;font-weight:bold;">合计{{priceTotal}}元</span>
                    </template>
                </van-field>
            </van-cell-group>
            <div style="margin: 0.16rem;">
                <van-button round block type="primary" native-type="submit" color="#4fc08d">
                    立即微信下单
                </van-button>
            </div>
        </van-form>
        <div class="infoitem">
            <h2 style="font-size: 0.36rem;border-bottom: solid 0.01rem #B8B8B8FF;margin: 0.1rem auto">其他说明</h2>
            <div class="info_content" style="text-indent: 0.5rem">
                根据国家工信部《电话用户真实身份信息登记规定》（中华人民共和国工业和信息化部令第25号）文件要求，用户新办理手机号码必须实名制登记。
                <span class="red">购买成功后，请保持电话畅通，我们的客服会及时与您取得联系，并且需要您提供实名制信息。要求如下图：</span>
            </div>
            <div class="info_content">
                <img :src="require('../assets/images/photos.png')" style="width: 6.6rem;height: 6.6rem;margin: 0.1rem auto" />
            </div>
        </div>
    </div>
</template>

<script>
    import isIdcard from "../assets/js/idcard";
    // import { areaList } from '@vant/area-data'
    import { Dialog } from 'vant';
    export default {
        name: "PhoneNumberOrder",
        setup() {
            return {
              // areaList
            };
        },
        data(){
            return{
                phoneObj:{},
                orderObj:{
                    phoneNumber:"",//选购号码
                    code:"",//验证码
                    checked:true,//已阅读协议
                    customerName:"",//渠道
                    clickid:"",//事件id
                    costPrice:0,//零售价
                    advanceFee:0,//预存话费
                    userInfo:{
                        userName:"",//真实姓名
                        idCard:"",//身份证号
                        phone:"",//联系电话
                        province:"",//省
                        city:"",//市
                        area:"",//区
                        address:""//详细地址
                    }
                },
                areaStr:"点击选择省市区",//地址
                showArea:false,//省市区开关
                showAddress:false,//详细地址开关
                protocolShow:false,//协议弹出层开关
                // 发送短信按钮
                sendCodeDisable: false,
                sendCodeText: '发送短信',
                seconds: 60,
            }
        },
        computed:{
            //计算价格总和
            priceTotal:function f() {
                var priceTotal = parseInt(this.phoneObj.serviceFee) + parseInt(this.phoneObj.advanceFee)
                return priceTotal
            }
        },
        //获取路由参数
        created() {
            this.phoneObj.phoneNumber = this.$route.query.phoneNumber;
            this.phoneObj.serviceFee = this.$route.query.serviceFee;
            this.phoneObj.optId = this.$route.query.optId;
            this.phoneObj.area = this.$route.query.area;
            this.phoneObj.province = this.$route.query.province;
            this.phoneObj.advanceFee = this.$route.query.advanceFee;

            this.orderObj.phoneNumber = this.$route.query.phoneNumber;
            this.orderObj.costPrice = this.$route.query.serviceFee;
            this.orderObj.advanceFee = this.$route.query.advanceFee;
        },
        methods:{
            //返回上一页
            back(){
                this.$router.go(-1)
            },
            // 验证身份证号码的合法性
            idCardValidator(val){
                let r = isIdcard(val);
                if(!r){
                    return "身份证号码不合法，请填写正确的身份证号码";
                }
            },
            // 验证联系电话的合法性
            phoneValidator(val){
                if(!/^1[3456789]\d{9}$/.test(val)){
                    return "请填写正确的联系电话";
                }
            },
            // 验证姓名的合法性
            nameValidator(val){
                if(!/^[\u4e00-\u9fa5·]{2,16}$/.test(val)){
                    return "请填写正确的开户人姓名";
                }
            },
            copyPhoneNumber(){
                var phonenumber = this.phoneObj.phoneNumber
                var copyNumber = phonenumber.substring(phonenumber.length-8)
                const input = document.createElement('input');
                input.setAttribute('readonly', 'readonly');
                input.setAttribute('value', copyNumber);
                document.body.appendChild(input);
                input.select();
                if (document.execCommand('copy')) {
                    document.execCommand('copy');
                    Dialog.alert({
                        message: '已成功复制号码后8位'+copyNumber,
                        theme: 'round-button',
                    }).then(() => {
                        // on close
                    });
                }
                document.body.removeChild(input);
            },

            // 检测联系号码填写是否正确
            checkPhone(){
                return /^1[3456789]\d{9}$/.test(this.orderObj.userInfo.phone);
            },
            // 发送短信验证码
            sendCode(){
                if(this.checkPhone()){
                    this.sendCodeDisable = true;
                    let url = "/haoma52-server/index/send-code";
                    let params = {
                        phoneNumber: this.orderObj.phoneNumber,
                        phone: this.orderObj.userInfo.phone
                    };
                    this.$axios.post(url, params).then(resp => {
                        if(resp.data.status == this.SUCCESS){
                            // 开始倒计时
                            this.startCount();
                        }else{
                            Dialog.alert({
                                message: resp.data.message
                            });
                        }
                    });
                }
            },
            // 开启倒计时 60s
            startCount(){
                if(this.seconds > 0){
                    this.sendCodeText = this.seconds-- +"后再次发送"
                    let _this = this;
                    setTimeout(function(){_this.startCount()}, 1000);
                }else{
                    this.sendCodeDisable = false;
                    this.sendCodeText = "发送验证码";
                    this.seconds = 60;
                }

            },
            //省市区确定回调函数
            onConfirm(item){
                this.orderObj.userInfo.province=item[0].name
                this.orderObj.userInfo.city=item[1].name
                this.orderObj.userInfo.area=item[2].name
                this.areaStr = item[0].name + "-" + item[1].name + "-" + item[2].name;
                this.showArea=false
                this.showAddress=true
            },
            //表单提交回调函数
            onSubmit(){

                this.orderObj.customerName = this.$route.params.source;
                if(this.$route.query.clickid){//头条
                    this.orderObj.clickid = this.$route.query.clickid;
                }else if(this.$route.query.gdt_vid){//广点通
                    this.orderObj.clickid = this.$route.query.gdt_vid;
                }else if(this.$route.query.landingid){
                    this.orderObj.clickid = this.$route.query.landingid;
                }else if(this.$route.query.callback){//快手
                    this.orderObj.clickid = this.$route.query.callback;
                }

                let url = "/haoma52-server/order/submitOrder";
                let params = this.orderObj;
                this.$axios.post(url, params).then(resp => {
                    if(resp.data.status == this.SUCCESS){
                        Dialog.alert({
                            message: resp.data.status
                        });
                    }else{
                        Dialog.alert({
                            message: resp.data.message
                        });
                    }
                });

            }
        }
    }
</script>

<style scoped>
    .nav{
        display: block;
        color: #323233;
        font-size: 0.16rem;
        font-family: Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 0.8rem;
        background-color: #fff;
        margin:0.1rem auto;
    }
    .nav-title{
        display: block;
        font-weight: 600;
        font-size: 0.36rem;
        text-transform: capitalize;
    }
    .nav_back{
        display: block;
        position: absolute;
        top:0.16rem;
        left: 0.16rem;
        width: 0.5rem;
        height: 0.5rem;
        cursor: pointer;
    }
    .subject{
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: #CB0101;
    }
    .subject_b{
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .liang_phonenumber{
        font-size: 0.6rem;
        text-align: center;
        line-height: 0.8rem;
        width: 100%;
        color: #ffffff;
        font-weight: bold;
        margin-top:0.3rem ;
    }
    .copy_button{
        background-color: white;
        width: 30%;
        height: 0.6rem;
        margin: 0.1rem auto;
        font-size: 0.24rem;
        text-align: center;
        color: red;
        border: solid 0;
        border-radius:0.3rem;
    }
    .text-area{
        width: 100%;
    }
    .text{
        display: block;
        width: 1.6rem;
        height: 1rem;
        font-size: 0.3rem;
        text-align: center;
        line-height: 0.8rem;
        color: #ffffff;
    }
    .text_a{
        display: block;
        width: 100%;
        height: 40%;
        font-size: 0.3rem;
        text-align: center;
    }
    .text_b{
        display: block;
        width: 98%;
        height: 2rem;
        font-size: 0.27rem;
        text-align: left;
        line-height: 0.4rem;
        margin:0 auto;
        margin-top: 0.2rem;
        border-top: solid 0.01rem #B8B8B8FF;
    }
    .service_fee{
        width: 45%;
        height: 0.8rem;
        margin-top: 0.2rem;
    }
    .border{
        border-left: solid 0.01rem #B8B8B8FF;
    }
    .price{
        display: block;
        width: 100%;
        height: 40%;
        font-size: 0.3rem;
        text-align: center;
        color: #F5A54B;
        margin:0.1rem auto;
    }
    .order_info {
        width: 100%;
        margin: 0.1rem auto;
        height: 0.6rem;
        line-height: 0.6rem;
        background: #CB0101;
        color: #ffffff;
        font-size: 0.3rem;
        text-align: center;
        border-radius: 0.6rem;
    }
    .infoitem {
        width: 7rem;
        margin: 0 auto;
        background-color: #ffffff;
        padding: 0.05rem 0.1rem;
        margin: 0 auto;
    }
    .info_content {
        display: flex;
        font-size: 0.28rem;
        flex-wrap: wrap;
    }
    .popup{
        width: 100%;
        height: 100%;
        background: #ffffff;
        overflow-y: auto;
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        font-family: 微软雅黑;
        font-size: 0.22rem;
    }
    .content {
        width: 90%;
        margin: 0 auto;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
    }
    .content p{
       text-indent: 2em;
    }
    .content h2 {
        display: block;
        font-size: 0.36rem;
        margin-block-start: 0.83rem;
        margin-block-end: 0.83rem;
        margin-inline-start: 0;
        margin-inline-end: 0;
        font-weight: bold;
        text-align: center;
    }
</style>
