<template>
    <div class="nav">
        <div class="nav-title">返回继续选号</div>
        <img class="nav_back" :src="require('../assets/images/arrow-left-bold.png')" @click="back"/>
    </div>
    <div class="subject">
        <div class="liang_phonenumber">{{phoneObj.phoneNumber}}</div>
        <button class="copy_button" type="button" @click="copyPhoneNumber">复制号码后8位</button>
        <div class="text-area"></div>
        <span class="text">{{phoneObj.province}}</span>
        <span class="text">{{phoneObj.area}}</span>
        <span class="text" v-if="phoneObj.optId == 1">中国移动</span>
        <span class="text" v-else-if="phoneObj.optId == 2">中国联通</span>
        <span class="text" v-else-if="phoneObj.optId == 3">中国电信</span>
        <span class="text" v-else-if="phoneObj.optId == 4">民营运营商</span>
    </div>
    <div class="subject_b">
        <div class="service_fee">
            <span class="text_a">服务费</span>
            <span class="price">{{phoneObj.serviceFee}}元</span>
        </div>
        <div class="service_fee border">
            <span class="text_a">代收预存款</span>
            <span class="price" v-if="phoneObj.advanceFee == ''||phoneObj.advanceFee == null">0.0元</span>
            <span class="price" v-else>{{phoneObj.advanceFee}}元</span>
        </div>
    </div>
    <div class="text_b">
        <p>靓号月保底129元2年，立即到账0元话费，开户5G畅爽冰激凌129元（30G通用流量+500分钟通话）套餐，激活扣除本月月费(接受个性定制)</p>
    </div>
</template>

<script>
    import { Dialog } from 'vant';
    export default {
        name: "PhoneNumberDetails",
        data(){
            return{
                phoneObj:{},
            }
        },
        computed:{
            //计算价格总和
            priceTotal:function f() {
                var priceTotal = parseInt(this.phoneObj.serviceFee) + parseInt(this.phoneObj.advanceFee)
                return priceTotal
            }
        },
        //获取路由参数
        created() {
            this.phoneObj.phoneNumber = this.$route.query.phoneNumber
            this.phoneObj.serviceFee = this.$route.query.serviceFee
            this.phoneObj.optId = this.$route.query.optId
            this.phoneObj.area = this.$route.query.area
            this.phoneObj.province = this.$route.query.province
            this.phoneObj.advanceFee = this.$route.query.advanceFee
        },
        methods:{
            //返回上一页
            back(){
                this.$router.go(-1)
            },
            copyPhoneNumber(){
                var phonenumber = this.phoneObj.phoneNumber
                var copyNumber = phonenumber.substring(phonenumber.length-8)
                const input = document.createElement('input');
                input.setAttribute('readonly', 'readonly');
                input.setAttribute('value', copyNumber);
                document.body.appendChild(input);
                input.select();
                if (document.execCommand('copy')) {
                    document.execCommand('copy');
                    Dialog.alert({
                        message: '已成功复制号码后8位'+copyNumber,
                        theme: 'round-button',
                    }).then(() => {
                        // on close
                    });
                }
                document.body.removeChild(input);
            }
        }
    }
</script>

<style scoped>
    .nav{
        display: block;
        color: #323233;
        font-size: 0.16rem;
        font-family: Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 0.8rem;
        background-color: #fff;
        margin:0.1rem auto;
    }
    .nav-title{
        display: block;
        font-weight: 600;
        font-size: 0.36rem;
        text-transform: capitalize;
    }
    .nav_back{
        display: block;
        position: absolute;
        top:0.16rem;
        left: 0.16rem;
        width: 0.5rem;
        height: 0.5rem;
        cursor: pointer;
    }
    .subject{
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: #CB0101;
    }
    .subject_b{
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .liang_phonenumber{
        font-size: 0.6rem;
        text-align: center;
        line-height: 0.8rem;
        width: 100%;
        color: #ffffff;
        font-weight: bold;
        margin-top:0.3rem ;
    }
    .copy_button{
        background-color: white;
        width: 30%;
        height: 0.6rem;
        margin: 0.1rem auto;
        font-size: 0.24rem;
        text-align: center;
        color: red;
        border: solid 0;
        border-radius:0.3rem;
    }
    .text-area{
        width: 100%;
    }
    .text{
        display: block;
        width: 1.6rem;
        height: 1rem;
        font-size: 0.3rem;
        text-align: center;
        line-height: 0.8rem;
        color: #ffffff;
    }
    .text_a{
        display: block;
        width: 100%;
        height: 40%;
        font-size: 0.3rem;
        text-align: center;
    }
    .text_b{
        display: block;
        width: 98%;
        height: 2rem;
        font-size: 0.27rem;
        text-align: left;
        line-height: 0.4rem;
        margin:0 auto;
        margin-top: 0.2rem;
        border-top: solid 0.01rem #B8B8B8FF;
    }
    .service_fee{
        width: 45%;
        height: 0.8rem;
        margin-top: 0.2rem;
    }
    .border{
        border-left: solid 0.01rem #B8B8B8FF;
    }
    .price{
        display: block;
        width: 100%;
        height: 40%;
        font-size: 0.3rem;
        text-align: center;
        color: #F5A54B;
        margin:0.1rem auto;
    }
    .order_info {
        width: 100%;
        margin: 0.1rem auto;
        height: 0.6rem;
        line-height: 0.6rem;
        background: #CB0101;
        color: #ffffff;
        font-size: 0.3rem;
        text-align: center;
        border-radius: 0.6rem;
    }
    .content p{
        text-indent: 2em;
    }
    .content h2 {
        display: block;
        font-size: 0.36rem;
        margin-block-start: 0.83rem;
        margin-block-end: 0.83rem;
        margin-inline-start: 0;
        margin-inline-end: 0;
        font-weight: bold;
        text-align: center;
    }
</style>
