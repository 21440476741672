<template>
  <router-view v-wechat-title="$route.meta.title"/>
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
@media (min-width: 320px){html{font-size: 42.6667px;} }
@media (min-width: 360px){html{font-size: 48px;} }
@media (min-width: 375px){html{font-size: 50px;} }
@media (min-width: 384px){html{font-size: 51.2px;} }
@media (min-width: 414px){html{font-size: 55.2px;} }
@media (min-width: 448px){html{font-size: 59.7333px;} }
@media (min-width: 480px){html{font-size: 48px;} }
@media (min-width: 512px){html{font-size: 68.2667px;} }
@media (min-width: 544px){html{font-size: 72.5333px;} }
@media (min-width: 576px){html{font-size: 76.8px;} }
@media (min-width: 608px){html{font-size: 81.0667px;} }
@media (min-width: 640px){html{font-size: 85.3333px;} }
@media (min-width: 750px){html{font-size: 100px;} }

*{
  margin: 0;
  padding: 0;
}
body{
  width: 7.5rem;
  margin: 0 auto !important;
}
.red{
  color: #ed1c24;
}
.bottomMsg{
  margin-bottom: 0.2rem;
  padding-top: 0.15rem;
  color: darkgray;
  font-size: 0.2rem;
  text-align: center;
}


</style>
