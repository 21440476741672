<template>
	<div>
		<!-- 两端对齐 -->
		<van-row justify="space-between">
			<van-col span="24">
				<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
					<van-swipe-item>
						<van-image :src="require('../assets/images/banner_index.jpg')" style="display: block"></van-image>
					</van-swipe-item>
					<van-swipe-item @click="showMore()">
						<van-image :src="require('../assets/images/fangzha_banner.png')" style="display: block"></van-image>
					</van-swipe-item>
					<van-swipe-item @click="showMore()">
						<van-image :src="require('../assets/images/unicom_bsp.png')" style="display: block"></van-image>
					</van-swipe-item>
				</van-swipe>
				
			</van-col>
			<van-col span="24">
				<van-notice-bar
					:left-icon="require('../assets/images/laba.png')"
					text="靓号有保底消费，有在网合约要求，请根据自己的实际消费情况，理性消费。"/>
			</van-col>
			<van-col span="24" style="font-size: 0.24rem; text-align: center; margin: 0.05rem;">
				客服热线：0571-28888520
			</van-col>

			<van-col span="24">
				<van-search
					v-model.tirm=qryObj.phoneNumber
					background="rgb(223 223 223)"
					label="模糊查询"
					placeholder="请输入搜索关键词"
					shape="round"
					show-action>
					<template #action>
						<div @click="loadData(1)">搜索</div>
					</template>
				</van-search>
			</van-col>
			<van-col span="8">
				<van-cell center title="尾号">
					<template #right-icon>
						<van-switch v-model=qryObj.trail size="16px"/>
					</template>
				</van-cell>
			</van-col>
			<van-col span="8">
				<van-cell center title="不含4">
					<template #right-icon>
						<van-switch v-model=this.qryObj.exclude size="16px"/>
					</template>
				</van-cell>
			</van-col>
			<van-col span="8">
				<van-cell center>
					<van-button block color="#4169E1" round type="mini" @click="clear">清空条件</van-button>
				</van-cell>
			</van-col>
		</van-row>

		<van-row justify="space-between">
			<van-col class="van-hairline--surround" span="8">
				<van-cell class="van-ellipsis">
					{{ this.operator }}
				</van-cell>
			</van-col>
			<van-col class="van-hairline--surround" span="8">
				<van-cell arrow-direction="down" class="van-ellipsis" is-link @click="AddressShow = true">
					{{ this.address }}
				</van-cell>
				<van-popup v-model:show=AddressShow :style="{ height: '45%' }" position="bottom">
					<van-area
						:area-list="queryAreaList"
						:columns-num="2"
						title="请选择归属地"
						@cancel="AddressShow = false"
						@confirm="AddressConfirm"
					/>
				</van-popup>
			</van-col>
			<van-col class="van-hairline--surround" span="8">
				<van-cell arrow-direction="down" class="van-ellipsis" is-link @click="this.ruleShow = true">{{
						this.rule
					}}
				</van-cell>
				<van-popup v-model:show=this.ruleShow :style="{ height: '45%' }" position="bottom">
					<van-area
						:area-list="rulesList"
						:columns-num="2"
						title="请选择号码规律"
						@cancel="ruleShow = false"
						@confirm="rulesConfirm"
					/>
				</van-popup>
			</van-col>
		</van-row>


		<div style="margin-top:0.1rem;margin-bottom: 1.56rem">

			<van-list v-model="loading" :finished="finished" :finished-text="result" @load="loadData">
				<article class="phone_number_article">

					<div v-for="item in list" :key="item" class="phonelist" @click="showPopup(item)">
						<span class="liang_phonenumber" v-html=item.display></span>
						<span class="liang_flag">靓</span>
						<span class="opt_Id">
                <span v-if="item.optId == 1">中国移动</span>
                <span v-else-if="item.optId == 2">中国联通</span>
                <span v-else-if="item.optId == 3">中国电信</span>
                <span v-else-if="item.optId == 4">民营运营商</span>
            </span>
						<span class="opt_area">{{ item.area }}</span>
					</div>


				</article>


			</van-list>

		</div>
		
		
		<div class="copyright">
			<span>版权所有：杭州我爱号码网电子商务有限公司</span>
			<span>联系电话：0571-28888520</span>
			<span>
				<a href="https://beian.miit.gov.cn/" target="_blank">
					<span v-if="fullPath.indexOf('52tongxun.com') != -1">浙ICP备19034816号-6</span>
					<span v-else-if="fullPath.indexOf('52tongxun.cn') != -1">浙ICP备19034816号-5</span>
					<span v-else-if="fullPath.indexOf('52tongxin.cn') != -1">浙ICP备19034816号-4</span>
					<span v-else-if="fullPath.indexOf('52kuandai.com') != -1">浙ICP备19034816号-3</span>
					<span v-else>浙ICP备19034816号-1</span>
				</a>
			</span>
		</div>

		<!--下单 -->
		<van-popup v-model:show="show" position="bottom">
			<div class="order_div">
				<div class="subject1">
					<div class="liang_phonenumber1">{{ phoneObj.phoneNumber }}</div>
					<div class="text-area1"></div>
					<span class="text1">{{ phoneObj.province }}</span>
					<span class="text1">{{ phoneObj.area }}</span>
					<span v-if="phoneObj.optId == 1" class="text1">中国移动</span>
					<span v-else-if="phoneObj.optId == 2" class="text1">中国联通</span>
					<span v-else-if="phoneObj.optId == 3" class="text1">中国电信</span>
					<span v-else-if="phoneObj.optId == 4" class="text1">民营运营商</span>
				</div>
				<div class="text_b1">
					<p>{{ phoneObj.introduce }}</p>
					<div class="order_info1">填写订单信息马上领取</div>
				</div>
				<van-form @submit="onSubmit">
					<van-cell-group inset>
						<van-field
							v-model="orderObj.userInfo.userName"
							:rules="[{ required: true, message: '请正确填写真实姓名', validator: nameValidator }]"
							label="真实姓名"
							name="姓名"
							placeholder="请输入开户人真实姓名"
						/>
						<van-field
							v-model="orderObj.userInfo.idCard"
							:rules="[{ required: true, message: '请正确填写身份证号', validator: idCardValidator}]"
							label="身份证号"
							name="身份证号"
							placeholder="请输入开户人真实身份证"
						/>
						<van-field
							v-model:placeholder="areaStr"
							is-link
							label="地区选择"
							name="area"
							readonly
							@click="showArea = true"
						/>
						<van-popup v-model:show="showArea" position="bottom">
							<van-area
								:area-list="phoneObj.optId==1?ydAreaList:areaList"
								@cancel="showArea = false"
								@confirm="onConfirm"
							/>
						</van-popup>
						<van-field
							v-show="showAddress"
							v-model="orderObj.userInfo.address"
							:rules="[{ required: true, message: '请输入正确的详细地址' }]"
							label="详细地址"
							name="详细地址"
							placeholder="请输入详细地址"
						/>
						<van-field
							v-model="orderObj.userInfo.phone"
							:rules="[{ required: true, message: '请正确填写联系电话', validator: phoneValidator}]"
							label="联系电话"
							name="联系电话"
							placeholder="请输入开户人真实联系电话"
							type="tel"
						/>
						<van-field
							v-model="orderObj.code"
							:rules="[{ required: true, message: '请正确填写验证码' }]"
							label="验证码"
							name="验证码"
							placeholder="请输入验证码"
							type="number">
							<template #button>
								<van-button :disabled="sendCodeDisable" size="small" type="primary" @click="sendCode()">
									{{ sendCodeText }}
								</van-button>
							</template>
						</van-field>
						<van-field :rules="[{ required: true, message: '请阅读并勾选' }]" name="checkbox">
							<template #input>
								<van-checkbox v-model="orderObj.checked" shape="square">我已阅读并同意</van-checkbox>
							</template>
							<template #right-icon>
								<span style="color: cornflowerblue" @click="protocolShow = true">《关于客户个人信息收集、<br>使用规则的公告》</span>
							</template>
						</van-field>

					</van-cell-group>
					<div style="margin: 0.16rem;margin-bottom:0.5rem;">
						<van-button :color="submitBtnFlag?'grey':'#4fc08d'" :disabled="submitBtnFlag" block native-type="submit" round
									type="primary">
							立即领取
						</van-button>
					</div>
				</van-form>
			</div>
		</van-popup>


		<van-popup v-model:show="protocolShow" :style="{ height: '100%' }" position="bottom" round>
			<div class="popup1">
				<div class="content">
					<h2>关于客户个人信息收集、使用规则的公告</h2>
					<div style="max-height: 22rem">
						<p style="text-indent: 0">尊敬的客户：</p>
						<p>
							根据《中华人民共和国反恐怖主义法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在中国联合网络通信有限公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等）办理固定电话、移动电话（含无线上网卡）入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效证件原件并进行查验、登记，登记信息包括姓名、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。
						</p>
						<p>如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。</p>
						<p>
							为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息：
						</p>
						<p>(a)事先获得客户的明确授权；</p>
						<p>(b)根据有关的法律法规要求；</p>
						<p>(c)按照相关司法机关和/或政府主管部门的要求；</p>
						<p>(d)为维护社会公众的利益所必需且适当；</p>
						<p>(e)为维护我公司或客户的合法权益所必需且适当。</p>
						<p>中国联合网络通信有限公司</p>
						<br>
						<van-button block hairline plain style="margin: 0 auto !important;" type="primary"
									@click="protocolShow = false">我已阅读
						</van-button>
						<br>
						<br>
						<br>
						<br>
					</div>
				</div>
			</div>
		</van-popup>

		<div style="width: 7.5rem; height: 0.1rem; margin: 0 auto; position: fixed; bottom: 0"></div>


		<div class="goTop" @click="backtop">
			<van-icon name="back-top"/>
		</div>
	</div>
</template>

<script>
import {queryAreaList} from '@/assets/js/queryAreaList'
import {areaList} from '@/assets/js/areaList'
import {rulesList} from '@/assets/js/rulesList'

import {Dialog} from 'vant';
import isIdcard from "@/assets/js/idcard";
import {ref} from 'vue';

export default {

	name: 'MainIndex',
	setup() {
		// eslint-disable-next-line no-undef
		//弹出层页面是否显示
		const show = ref(false);
		//存放办理号码的相关信息
		const phoneObj = {};

		// 移动收货地址
		const ydAreaList = {};

		//点击弹出层调用的方法
		const showPopup = (item) => {
			show.value = true;
			//下单弹窗所需参数

			phoneObj.phoneNumber = item.phoneNumber;
			phoneObj.serviceFee = item.serviceFee;
			phoneObj.optId = item.optId;
			phoneObj.area = item.area;
			phoneObj.province = item.province;
			phoneObj.advanceFee = item.advanceFee;
			phoneObj.introduce = item.introduce;
			phoneObj.autoOrderId = item.autoOrderId;
			phoneObj.priceTotal = parseInt(phoneObj.serviceFee) + parseInt(phoneObj.advanceFee);

			// 如果运营商为移动，则修改收货地址为号码的地址
			if (item.optId == 1) {
				Object.keys(areaList.province_list).map(key => {
					if (areaList.province_list[key] == item.province) {
						let provinceList = {}
						provinceList[key] = areaList.province_list[key];
						ydAreaList.province_list = provinceList;
					}
				})
				let cityKey = null;
				Object.keys(areaList.city_list).map(key => {
					if (areaList.city_list[key] == item.area) {
						let cityList = {};
						cityKey = key;
						cityList[key] = areaList.city_list[key];
						ydAreaList.city_list = cityList;
					}
				})

				let countyList = {};
				Object.keys(areaList.county_list).map(key => {
					if (cityKey < key && key < (Number(cityKey) + 100)) {
						countyList[key] = areaList.county_list[key];
					}
				})
				ydAreaList.county_list = countyList;
			}

		};
		return {
			queryAreaList,
			areaList,
			rulesList,
			ydAreaList,
			show,
			showPopup,
			phoneObj,
		};
	},

	data() {
		return {
            fullPath: "",
			locationSearch: "",//路径？的内容
			source: "",//渠道
			qryObj: {},//查询对象
			bottomSwitch: false,//标签栏默认底部开关
			operator: "中国联通",//运营商
			address: "归属地",//归属地
			AddressShow: false,//归属地弹出层开关
			rule: "规律",//规律
			ruleShow: false,//规律弹出层开关
			price: "价格",//价格
			priceShow: false,//价格弹出层开关
			serverShow: false,//客服热线开关
			submitBtnFlag: false,//提交按钮开关
			//价格选项数据
			priceActions: [
				{name: '全部'},
				{name: '1元专区', value: 1},
				{name: '2~100元', value: 2},
				{name: '101~200元', value: 3},
				{name: '201~300元', value: 4},
				{name: '301~500元', value: 5},
				{name: '501~1000元', value: 6},
				{name: '1001~5000元', value: 7},
				{name: '5001及以上', value: 8},
			],
			pulldown: true,//开启下拉刷新
			pullup: true,//开启上拉加载
			currentPage: 1,//当前页
			list: [],//列表数据
			emptyShow: false,//暂无数据提示开关
			//下单
			orderObj: {
				phoneNumber: "",//选购号码
				code: "",//验证码
				checked: true,//已阅读协议
				customerName: "",//渠道
				clickid: "",//事件id
				costPrice: 0,//零售价
				advanceFee: 0,//预存话费
				userInfo: {
					userName: "",//真实姓名
					idCard: "",//身份证号
					phone: "",//联系电话
					province: "",//省
					city: "",//市
					area: "",//区
					address: ""//详细地址
				}
			},
			areaStr: "点击选择省市区",//地址
			showArea: false,//省市区开关
			showAddress: false,//详细地址开关
			protocolShow: false,//协议弹出层开关
			// 发送短信按钮
			sendCodeDisable: false,
			sendCodeText: '发送短信',
			seconds: 60,
			loading: false,
			finished: false,
			result: ""
		}
	},
	computed: {},
	created() {
		this.source = this.$route.params.source;
		this.loadData(1);
		this.locationSearch = location.search;
        this.fullPath = window.location.href;

	},
	methods: {

        showMore(){
            this.$router.push('/rules');
		},
		//查询客服热线
		queryServer() {
			this.serverShow = true;
		},
		//刷新主页
		reLoadIndex() {
			location.reload();
		},
		backtop() {
			var timer = setInterval(function () {
				let osTop = document.documentElement.scrollTop || document.body.scrollTop;
				let ispeed = Math.floor(-osTop / 5);
				document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
				this.isTop = true;
				if (osTop === 0) {
					clearInterval(timer);
				}
			}, 30)
		},
		//根据条件查询数据，并覆盖原数据
		loadData(pageNum) {
			// 如果上一次还没加载完成，停止下一次加载
			if (this.loading) {
				return;
			}
			this.loading = true;
			this.result = "";

			if (pageNum) {
				this.currentPage = pageNum;
			}

			if (this.currentPage == 1) {
				this.list = [];
			}


			let url = '/itongxun-server/index/index-list'
			this.qryObj.pageNum = this.currentPage;
			// 设置请求参数

			this.$axios.post(url, this.qryObj).then(resp => {
				if (resp.data.status != this.SUCCESS) {
					Dialog.alert({
						message: '服务端异常！',
						theme: 'round-button',
					})
					return;
				}
				let list = resp.data.list;
				if (list.length == 0) {
					if (this.currentPage == 1) {
						this.result = "没有符合您搜索条件的号码，换个条件试试";
					} else {
						this.result = "没有更多了，我是有底线的";
					}
					this.finished = true;
				} else {
					for (let i = 0; i < list.length; i++) {
						this.list.push(list[i]);
					}
					if (list.length < 12) {
						this.result = "没有更多了，我是有底线的";
						this.finished = true;
					} else {
						this.currentPage++;
					}
				}
				this.loading = false;

			});
		},

		//地区选择
		AddressConfirm(item) {
			this.finished = false;
			this.AddressShow = false;
			let p = item[0].name
			let a = item[1].name
			this.address = a
			if (a == "" || a == "全部") {
				this.address = p
			}
			this.qryObj.province = p
			this.qryObj.area = a
			this.loadData(1)
		},
		//规律选择
		rulesConfirm(item) {
			this.finished = false;
			this.ruleShow = false;
			let p = item[0].name
			let a = item[1].name
			this.rule = p + "-" + a
			this.qryObj.pType = p
			this.qryObj.aType = a
			this.loadData(1)
		},
		//价格选择
		priceSelect(item) {
			this.finished = false;
			this.priceShow = false;
			this.price = item.name
			this.qryObj.price = item.value
			this.loadData(1)
		},
		//清空条件
		clear() {
			this.operator = "中国联通"
			this.address = "归属地"
			this.rule = "规律"
			this.price = "价格"
			this.qryObj = {}
			this.finished = false;
			this.loadData()
		},

		// 下单
		// 验证身份证号码的合法性
		idCardValidator(val) {
			let r = isIdcard(val);
			if (!r) {
				return "身份证号码不合法，请填写正确的身份证号码";
			}
		},
		// 验证联系电话的合法性
		phoneValidator(val) {
			if (!/^1[3456789]\d{9}$/.test(val)) {
				return "请填写正确的联系电话";
			}
		},
		// 验证姓名的合法性
		nameValidator(val) {
			if (!/^[\u4e00-\u9fa5·]{2,16}$/.test(val)) {
				return "请填写正确的开户人姓名";
			}
		},

		// 检测联系号码填写是否正确
		checkPhone() {
			return /^1[3456789]\d{9}$/.test(this.orderObj.userInfo.phone);
		},
		// 发送短信验证码
		sendCode() {
			if (this.checkPhone()) {
				this.sendCodeDisable = true;
				let url = "/itongxun-server/index/send-code";
				let params = {
					phoneNumber: this.phoneObj.phoneNumber,
					phone: this.orderObj.userInfo.phone
				};
				this.$axios.post(url, params).then(resp => {
					if (resp.data.status == this.SUCCESS) {
						// 开始倒计时
						this.startCount();
					} else {
						Dialog.alert({
							message: resp.data.message
						});
					}
				});
			}
		},
		// 开启倒计时 60s
		startCount() {
			if (this.seconds > 0) {
				this.sendCodeText = this.seconds-- + "后再次发送"
				let _this = this;
				setTimeout(function () {
					_this.startCount()
				}, 1000);
			} else {
				this.sendCodeDisable = false;
				this.sendCodeText = "发送验证码";
				this.seconds = 60;
			}

		},

		//省市区确定回调函数
		onConfirm(item) {
			this.orderObj.userInfo.province = item[0].name
			this.orderObj.userInfo.city = item[1].name
			this.orderObj.userInfo.area = item[2].name
			this.areaStr = item[0].name + "-" + item[1].name + "-" + item[2].name;
			this.showArea = false
			this.showAddress = true
		},
		//表单提交回调函数
		onSubmit() {
			//不可重复点击
			this.submitBtnFlag = true;
			this.orderObj.customerName = this.$route.params.source;

			//下单提交所需参数
			this.orderObj.phoneNumber = this.phoneObj.phoneNumber;
			this.orderObj.pOptId = this.phoneObj.optId;
			this.orderObj.pProvince = this.phoneObj.province;
			this.orderObj.introduce = this.phoneObj.introduce;
			this.orderObj.pArea = this.phoneObj.area;


			let url = "/itongxun-server/order/submitOrder";
			let params = this.orderObj;
			this.$axios.post(url, params).then(resp => {
				if (resp.data.status == this.SUCCESS) {
					//成功
					//关闭弹出框
					this.show = false;
					//清除表单参数
					this.clearOrderObj();
					Dialog.alert({message: "下单成功!"});
				} else {
					//失败
					if (resp.data.message != null && resp.data.message != '') {
						Dialog.alert({message: resp.data.message});
					} else {
						Dialog.alert({message: "下单异常，请联系客服"});
					}

				}
				//恢复点击
				this.submitBtnFlag = false;
			});

		},
		clearOrderObj() {
			this.orderObj = {
				phoneNumber: "",//选购号码
				code: "",//验证码
				checked: true,//已阅读协议
				customerName: "",//渠道
				clickid: "",//事件id
				costPrice: 0,//零售价
				advanceFee: 0,//预存话费
				userInfo: {
					userName: "",//真实姓名
					idCard: "",//身份证号
					phone: "",//联系电话
					province: "",//省
					city: "",//市
					area: "",//区
					address: ""//详细地址
				}
			}
		}
	},

}
</script>

<style scoped>
.order_div {
	width: 6.9rem;
	padding: 0.2rem;
	margin: 0 auto;
	background: #ffffff;
	font-size: 0.25rem !important;
}

.goTop {
	position: fixed !important;
	left: 0.1rem;
	bottom: 2rem;
	z-index: 999;
	font-size: 0.5rem;
	width: 1rem;
	height: 1rem;
	line-height: 1rem;
	text-align: center;
	background: #1989FA;
	border-radius: 1rem;
	opacity: 0.2;
}

.phone_number_article {
	width: 7.5rem;
	margin: 0.05rem auto;
	display: flex;
	flex-wrap: wrap;
	background: #f3f4f7;
}

.phone_number_article section {
	width: 3.55rem;
	display: flex;
	flex-wrap: wrap;
	padding: 0.05rem;
	margin-left: 0.08rem;
	margin-top: 0.08rem;
	margin-bottom: 0.08rem;
	text-align: center;
	background: #ffffff;

}

.phone_number_span {
	font-size: 0.35rem;
	width: 3.55rem;
	text-align: center;
	font-weight: bold;
}

.area_span {
	width: 3.55rem;
	text-align: center;
	color: #888888;
	font-size: 0.25rem;
	margin: 0.05rem auto;
}

.price_opt_span {
	width: 3.55rem;
	display: flex;
	justify-content: space-around;
	font-size: 0.3rem;
}

.wrapper {
	width: 7.5rem;
	background: #F5F5F5;
	overflow: hidden;
}

.wrapper .content {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.emptyimage {
	width: 100%;
	height: 45%;
	position: absolute;
}

.phonelist {
	width: 3.55rem;
	height: 1.8rem;
	background: #ffffff;
	border-radius: 0.05rem;
	margin: 0.1rem;
	text-decoration: none;
	display: flex;
	flex-wrap: wrap;
	align-content: space-around;
}

.liang_phonenumber {
	font-size: 0.39rem;
	text-align: center;
	width: 80%;
	color: #292826;
	font-weight: bold;
}

.liang_flag {
	font-size: 0.3rem;
	display: block;
	width: 0.4rem;
	height: 0.4rem;
	text-align: center;
	background-color: red;
	color: #ffffff;
}

.opt_area {
	text-align: right;
	width: 40%;
	color: #B8B8B8FF;
	font-size: 0.28rem;
	height: 0.42rem;
	margin-right: 0.3rem;
}

.opt_Id {
	text-align: center;
	width: 40%;
	color: #B8B8B8FF;
	font-size: 0.28rem;
	height: 0.42rem;
	margin-right: 0.2rem;
	margin-left: 0.2rem;
}

.price {
	display: block;
	width: 30%;
	font-size: 0.28rem;
	color: red;
	text-align: left;
	margin-left: 0.2rem;
	margin-right: 1rem;
}

.originalPrice {
	display: block;
	width: 30%;
	font-size: 0.28rem;
	color: #B8B8B8FF;
	text-align: center;
	text-decoration: line-through;
}

/*下单*/
.nav1 {
	display: block;
	color: #323233;
	font-size: 0.16rem;
	font-family: Open Sans, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB, Microsoft Yahei, sans-serif;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 0.8rem;
	background-color: #fff;
	margin: 0.1rem auto;
}

.nav-title1 {
	display: block;
	font-weight: 600;
	font-size: 0.36rem;
	text-transform: capitalize;
}

.nav_back1 {
	display: block;
	position: absolute;
	top: 0.16rem;
	left: 0.16rem;
	width: 0.5rem;
	height: 0.5rem;
	cursor: pointer;
}

.subject1 {
	width: 100%;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	background-color: #CB0101;
}

.subject_b1 {
	width: 100%;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.liang_phonenumber1 {
	font-size: 0.6rem;
	text-align: center;
	line-height: 0.8rem;
	width: 100%;
	color: #ffffff;
	font-weight: bold;
	margin-top: 0.3rem;
}

.copy_button1 {
	background-color: white;
	width: 30%;
	height: 0.6rem;
	margin: 0.1rem auto;
	font-size: 0.24rem;
	text-align: center;
	color: red;
	border: solid 0;
	border-radius: 0.3rem;
}

.text-area1 {
	width: 100%;
}

.text1 {
	display: block;
	width: 1.6rem;
	height: 1rem;
	font-size: 0.3rem;
	text-align: center;
	line-height: 0.8rem;
	color: #ffffff;
}

.text_a1 {
	display: block;
	width: 100%;
	height: 40%;
	font-size: 0.3rem;
	text-align: center;
}

.text_b1 {
	display: block;
	width: 98%;
	font-size: 0.27rem;
	text-align: left;
	line-height: 0.4rem;
	margin: 0 auto;
	margin-top: 0.2rem;
	border-top: solid 0.01rem #B8B8B8FF;
}

.service_fee1 {
	width: 45%;
	height: 0.8rem;
	margin-top: 0.2rem;
}

.border1 {
	border-left: solid 0.01rem #B8B8B8FF;
}

.price1 {
	display: block;
	width: 100%;
	height: 40%;
	font-size: 0.3rem;
	text-align: center;
	color: #F5A54B;
	margin: 0.1rem auto;
}

.order_info1 {
	width: 100%;
	margin: 0.1rem auto;
	height: 0.6rem;
	line-height: 0.6rem;
	background: #CB0101;
	color: #ffffff;
	font-size: 0.3rem;
	text-align: center;
	border-radius: 0.6rem;
}

.infoitem1 {
	width: 7rem;
	margin: 0 auto;
	background-color: #ffffff;
	padding: 0.05rem 0.1rem;
	margin: 0 auto;
}

.info_content1 {
	display: flex;
	font-size: 0.28rem;
	flex-wrap: wrap;
}

.popup1 {
	width: 100%;
	height: 100%;
	background: #ffffff;
	overflow-y: auto;
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	font-family: 微软雅黑;
	font-size: 0.22rem;
}

.content1 {
	width: 90%;
	margin: 0 auto;
	padding-left: 0.3rem;
	padding-right: 0.3rem;
}

.content1 p {
	text-indent: 2em;
}

.content1 h2 {
	display: block;
	font-size: 0.36rem;
	margin-block-start: 0.83rem;
	margin-block-end: 0.83rem;
	margin-inline-start: 0;
	margin-inline-end: 0;
	font-weight: bold;
	text-align: center;
}
.copyright{
    position: fixed !important;
    color: #ffffff;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.2rem;
    background-color: #323233;margin: 0 auto;
    text-align: center;
    font-size: 0.2rem;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.copyright span{
    width: 100%;
}
.copyright span a{
    color: #1989FA !important;
}
</style>
