<template>
    <div></div>

</template>

<script>

    export default {
        name: "WxPay",
    data(){
      return{
          tradeNo:"",//第三方订单号
          orderId:"",//订单id
          source:"",//渠道
      }
    },


    //获取路由参数
    created() {
        this.tradeNo = this.$route.params.tradeNo;
        this.source = this.$route.params.source;
        // h5唤醒支付
        this.initPay(this.source,this.tradeNo);
    },
    methods:{
        //h5唤醒支付
        initPay(source,tradeNo) {
            let url = '/haoma52-server/wxpay/go/' + source + "/" + tradeNo;
            // 设置请求参数
            this.$axios.post(url).then(resp => {
                if (resp.data.status == this.SUCCESS) {
                    let respJson = resp.data.data;
                    let urlH5 = respJson.url;
                    window.location.href = urlH5;
                }
            });
        },

        }
    }
</script>

<style scoped>
    .btnOutDiv{
        width: 100%;
    }
    .btn{
        width: 80%;
        left:10%;
    }
</style>
